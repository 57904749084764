<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		id: string;
		height?: string | number;
		width?: string | number;
		itemsCount?: number;
		rows?: number;
		gap?: string;
		prerender?: boolean;
		isDataLoaded?: boolean;
		hideOnLeave?: boolean;
	}>(),
	{
		height: "200px",
		width: "100%",
		itemsCount: 1,
		rows: 1,
		gap: "12px",
		isDataLoaded: true,
		hideOnLeave: false
	}
);

const targetRef = ref<HTMLElement | null>(null);
const inheritHeight = ref<number | null>(null);
const loadedElements = ref<Record<string, boolean>>({});
const height = computed(() => (typeof props.height === "number" ? `${props.height}px` : props.height));
const width = computed(() => (typeof props.width === "number" ? `${props.width}px` : props.width));
const rootMargin = computed(() => `${window?.innerHeight ? window?.innerHeight / 2 : 500}px`);

const isIntersecting = ref(false);

if (process.client) {
	const { stop } = useIntersectionObserver(
		targetRef,
		([{ isIntersecting: intersecting, boundingClientRect }]) => {
			isIntersecting.value = intersecting;

			if (intersecting) {
				inheritHeight.value = null;
				loadedElements.value[props.id] = true;

				if (!props.hideOnLeave) {
					stop();
				}
			} else if (props.hideOnLeave && boundingClientRect?.height) {
				inheritHeight.value = Math.ceil(boundingClientRect.height);
			}
		},
		{ threshold: 0, rootMargin: rootMargin.value }
	);
}

const isComponentRendered = computed(() => props.prerender || isIntersecting.value);
const isShowComponent = computed(() => props.isDataLoaded && isComponentRendered.value);
</script>

<template>
	<div
		:id="id"
		ref="targetRef"
		:class="{ 'lazy-skeleton': !isShowComponent && !prerender }"
		:style="inheritHeight ? { minHeight: Math.ceil(inheritHeight) + 'px' } : {}"
	>
		<slot v-if="!hideOnLeave" :is-intersected="isComponentRendered" />
		<ClientOnly
			v-else-if="isShowComponent && loadedElements[id]"
			v-show="isComponentRendered"
			class="lazy-component"
			:force-mount="prerender"
		>
			<slot :is-intersected="isComponentRendered" />
		</ClientOnly>

		<template v-else>
			<slot name="skeleton" />
			<div v-if="itemsCount > 1" class="skeleton-grid">
				<ASkeleton v-for="item in itemsCount" :key="item" :width="width" :height="height" />
			</div>
			<ASkeleton v-else :width="width" :height="height" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
.skeleton-grid {
	overflow: hidden;
	display: grid;
	grid-auto-flow: column dense;
	grid-template-rows: repeat(v-bind(rows), 1fr);
	grid-template-columns: repeat(auto-fill, v-bind(width));
	gap: v-bind(gap);
}
</style>
